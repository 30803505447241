import styled from 'styled-components';

type OpenProps = {
  open?: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    background-color: #000;
    position: fixed;
    width: 100%;
  }
`;

export const NavBox = styled.div<OpenProps>`
  margin: 20px;
  display: flex;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #000;
    transition: all 0.3s ease-in;
    top: 7vh;
    margin: 0;
    left: ${props => (props.open ? '-100%' : '0')};
  }
`;

export const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Hamburger = styled.div<OpenProps>(
  props => `
background-color: #fff;
width: 30px;
height: 3px;
transition: all 0.3s linear;
align-self: center;
position: relative;
transform: ${props.open ? 'rotate(-45deg)' : 'inherit'};

::before,
::after {
  width: 30px;
  height: 3px;
  background-color: #fff;
  content: '';
  position: absolute;
  transition: all 0.3s linear;
}

::before {
  transform: ${
    props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)'
  };
  top: -10px;
}

::after {
  opacity: ${props.open ? '0' : '1'};
  transform: ${props.open ? 'rotate(90deg) ' : 'rotate(0deg)'};
  top: 10px;
}
`
);
