import styled from 'styled-components';

export const ContentBanner = styled.div`
  margin: auto;
  padding: 60px 100px;
  max-width: 1200px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`;
