import styled from 'styled-components';
import { Link } from 'gatsby';

export const Item = styled.p`
  margin: 10px 20px;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: #fff;
`;

export const InternalLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

export const CTAButton = styled.div`
  background-color: #fff;
  border-radius: 4px;
`;
